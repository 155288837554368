exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-anciom-js": () => import("./../../../src/pages/projects/anciom.js" /* webpackChunkName: "component---src-pages-projects-anciom-js" */),
  "component---src-pages-projects-atlas-bms-js": () => import("./../../../src/pages/projects/atlas-bms.js" /* webpackChunkName: "component---src-pages-projects-atlas-bms-js" */),
  "component---src-pages-projects-bbrew-js": () => import("./../../../src/pages/projects/bbrew.js" /* webpackChunkName: "component---src-pages-projects-bbrew-js" */),
  "component---src-pages-projects-cop-a-cookie-js": () => import("./../../../src/pages/projects/cop-a-cookie.js" /* webpackChunkName: "component---src-pages-projects-cop-a-cookie-js" */),
  "component---src-pages-projects-dog-butler-js": () => import("./../../../src/pages/projects/dog-butler.js" /* webpackChunkName: "component---src-pages-projects-dog-butler-js" */),
  "component---src-pages-projects-dunedin-chamber-js": () => import("./../../../src/pages/projects/dunedin-chamber.js" /* webpackChunkName: "component---src-pages-projects-dunedin-chamber-js" */),
  "component---src-pages-projects-eupen-js": () => import("./../../../src/pages/projects/eupen.js" /* webpackChunkName: "component---src-pages-projects-eupen-js" */),
  "component---src-pages-projects-evalution-js": () => import("./../../../src/pages/projects/evalution.js" /* webpackChunkName: "component---src-pages-projects-evalution-js" */),
  "component---src-pages-projects-sesh-js": () => import("./../../../src/pages/projects/sesh.js" /* webpackChunkName: "component---src-pages-projects-sesh-js" */)
}

